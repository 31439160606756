const Book = (props) => {
    return (
        <article className="book">
            <img src={props.img} alt={props.title} />
            <h2>{props.title}</h2>
            <h4>{props.author} </h4>
            <button
                className="btn btn-primary"
                onClick={() => alert(props.title)}
            >
                Display Title
            </button>
        </article>
    );
};
export default Book;
