import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

import "./index.css";
import { books } from "./books.js";
import Book from "./Book.js";

function BookList() {
    return (
        <section className="booklist">
            {books.map((book) => {
                return <Book {...book} key={book.id} />;
            })}
        </section>
    );
}

ReactDOM.createRoot(document.getElementById("root")).render(<BookList />);
