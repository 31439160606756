import img1 from './images/atomichabits.jpg';
import img2 from './images/fourthwing.jpg';
import img3 from './images/houseofflames.jpg';

export const books = [
    {
        author: "Rebecca Yarros",
        title: "Fourth Wing",
        img: img2,
        id: 1,
    },
    {
        author: "James Clear",
        title: "Atomic Habits",
        img: img1,
        id: 2,
    },
    {
        author:"Sarah J. Maas",
        title:"House of Flame and Shadow (Crescent City, 3)",
        img:img3,
        id: 3,
    },
];
